import React, { FC } from 'react';

import { Main } from '@src/app/Main/Main';
import { EventSearchFilterPanel } from './components/EventSearchFilterPanel';
import { EventSearchResults } from './components/EventSearchResults';
import { EventSearchResultsContent } from './components/EventSearchResultsContent';

export const EventSearchPage: FC = () => {
  return (
    <Main id="eventSearchPage">
      <div id="eventSearchContent">
        <EventSearchFilterPanel />
        <EventSearchResultsContent>
          <EventSearchResults />
        </EventSearchResultsContent>
      </div>
    </Main>
  );
};
