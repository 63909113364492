/* eslint-disable arrow-body-style */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useEventSearchResultsStore } from '../mothership/eventSearchResults.state';
import { ResultsStatus } from '../types';

export const EventSearchResults = () => {
  const status = useEventSearchResultsStore((state) => state.resultsStatus);

  if (status === ResultsStatus.pendingSearch) {
    return (
      <div
        className="resultsPending"
        data-testid="event-search-results-pending"
        data-cy="event-search-results-pending"
      >
        <div className="iconLabel">
          <div className="iconLabelIcon">
            <IconMaterial
              icon="data_exploration"
              variant="outlined"
              size={100}
              color="#ccc"
            />
          </div>
          <div className="iconLabelLabel">Search for results</div>
        </div>
      </div>
    );
  }

  return null;
};
