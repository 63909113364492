/* eslint-disable arrow-body-style */
import React from 'react';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import { Router } from '@abyss/web/ui/Router';
import { AppHeader } from '../AppHeader';
import { useAuthState } from '../auth/auth.state';

export const AppBody = () => {
  const user = useAuthState((state) => state.user);
  return (
    <ThemeProvider>
      {/* @ts-ignore Abyss?? */}
      <ToastProvider position="top-center" />
      <AppHeader user={user} />
      <Router.Outlet />
    </ThemeProvider>
  );
};
