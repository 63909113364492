/* eslint-disable arrow-body-style */
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import React from 'react';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const PortalsSelect = () => {
  const options = useEventSearchFilterPanelStore(
    (state) => state.portalOptions
  );
  const value = useEventSearchFilterPanelStore(
    (state) => state.portalsSelected
  );

  const { setPortalValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-portals"
      data-value={value}
      data-options={JSON.stringify(options)}
    >
      <SelectInputMulti
        label="Portals"
        placeholder="Please select portals"
        value={value}
        onChange={setPortalValue}
        options={options}
        selectAll
      />
    </div>
  );
};
