/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import { useToast } from '@abyss/web/hooks/useToast';
import { config } from '@abyss/web/tools/config';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { AccountSection } from '@src/app/AccountSection';
import { useLogout } from '@src/hooks/user/useLogout';
import hotkeys from 'hotkeys-js';
import React from 'react';
import { useAuthState } from '../auth/auth.state';
import { sessionCountdown } from '../auth/sessionCountdown';
import { RouteUrl, isRoute } from '../../common/util/routeUrl';

const IS_LOCAL = config('APP_ENV') === 'local';

const SIDEBAR_WIDTH = 260;

const HeaderBar = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid $gray4',
  position: 'fixed',
  background: 'white',
  zIndex: 200,
  margin: 0,
  padding: 0,
});

export const AppHeader = ({ user }) => {
  const { toast } = useToast();
  sessionCountdown.setToast(toast);
  sessionCountdown.setOnTimedOut(onTimeout);
  const authenticated = useAuthState((state) => state.authenticated);
  const isLogoutPage = isRoute(RouteUrl.logout);

  const [, registerLogout] = useLogout({
    onCompleted: () => {
      console.info(`Successfully sent log to NESS`);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  hotkeys('f5,ctrl+r,command+r', (event) => {
    // Prevent the default refresh event
    event.preventDefault();
    console.log('Ignoring Refresh window!');
  });

  async function logout() {
    if (!IS_LOCAL) {
      const logBackInUrl = config('LOG_BACK_IN_URL')!;
      console.log(`log back-in url: ${logBackInUrl}`);
      fetch(logBackInUrl, {
        credentials: 'include',
        redirect: 'follow',
        mode: 'no-cors',
      })
        .then((response) => {
          console.log(`Logout Success @ ${Date.now()}:${response.statusText}`);
          return response;
        })
        .catch((reason) => {
          console.log(`Logout error:${JSON.stringify(reason)}`);
        });
    }
  }
  async function logoutAndRedirect() {
    registerLogout({
      variables: {
        nessOptions: {
          tags: ['UI', 'logout'],
          additionalFields: {
            'user-invoked': true,
            auto: false,
          },
        },
      },
    });

    await onLogOut();
  }

  async function onTimeout() {
    registerLogout({
      variables: {
        nessOptions: {
          tags: ['UI', 'logout', 'session', 'timeout'],
          additionalFields: {
            'user-invoked': false,
            session: 'timeout',
            auto: true,
          },
        },
      },
    });

    await onLogOut();
  }

  async function onLogOut() {
    await logout();
    window.location.href = `${RouteUrl.logout}?c=${new Date().getTime()}`;
    /**
     *  TODO: for now we'll turn this off as we need the EIS team to investigate this logout functionality further
     *  const url = 'https://maelstrom-cloud.optum.com/api/identity/logout';
     *  // @ts-ignore
     *  window.open(url);
     *
     */
  }

  return (
    <React.Fragment>
      <HeaderBar>
        <div id="mainHeader" data-testid="main-header" data-cy="main-header">
          <div id="headerContent">
            <a href="/" id="logoLink">
              <img src="/assets/dfeds_logo.svg" alt="dFEDS Logo" />
            </a>

            {authenticated && !isLogoutPage && (
              <div
                className="headerRight"
                data-testid="main-header-right"
                data-cy="main-header-right"
              >
                <div>
                  <Button
                    data-cy="logout"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={logoutAndRedirect}
                    size="$md"
                    variant="outline"
                  >
                    <span>Logout</span>
                  </Button>
                </div>

                <div id="accountSection" data-cy="menu-account">
                  <AccountSection sidebarWidth={SIDEBAR_WIDTH} user={user} />
                </div>
              </div>
            )}
          </div>
        </div>
      </HeaderBar>
    </React.Fragment>
  );
};
