import { SelectOption } from '@src/common/types';
import { dayjs } from '@abyss/web/tools/dayjs';

export const SELECTOR_MONTH_COUNT = 25;

const generateDates = (now: Date): Date[] => {
  const months: Date[] = [];
  for (let i = 0; i < SELECTOR_MONTH_COUNT; i++) {
    months.push(new Date(now.getFullYear(), now.getMonth() - i));
  }
  return months;
};

export const generateMonths = (now: Date): SelectOption[] => {
  const dates = generateDates(now);
  const options: SelectOption[] = [];
  dates.forEach((date) => {
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const value = `${date.getFullYear()}-${month}`;
    const label = dayjs(date).format('MMMM YYYY');
    options.push({
      label,
      value,
    });
  });
  return options;
};
