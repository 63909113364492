import { config } from '@abyss/api/tools/config';
import { Link } from '@abyss/web/ui/Link';
import { Main } from '@src/app/Main/Main';
import React, { useEffect } from 'react';

export const LogoutPage = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
  }, []);

  return (
    <Main className="center-content" id="logoutPage">
      <div id="logoutPageContent">
        <h1 data-cy="logged-out-header">You've been logged out</h1>
        <Link
          href={`${config('LOG_BACK_IN_URL')}`}
          openNewWindow
          data-cy="log-back-in-link"
        >
          Please click here to sign back in. You can then close this tab.
        </Link>
        <div id="logoutMessage">
          By logging into Optum Security Solutions, you are acknowledging that
          you are an authorized user; that unauthorized or improper use of Optum
          Security Solutions may result in disciplinary action as well as civil
          and criminal penalties; that Optum Security Solutions usage may be
          monitored, recorded, and subject to audit; that use of Optum Security
          Solutions indicated consent to monitoring and recording. The use of
          Optum Security Solutions by individual(s) or entity may allow access
          to information that is privileged, confidential and exempt from
          disclosure under applicable law, including the Health Insurance
          Portability and Accountability Act (PL 104-191).
        </div>
      </div>
    </Main>
  );
};
