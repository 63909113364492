/* eslint-disable arrow-body-style */

import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const ValueField = () => {
  const value = useEventSearchFilterPanelStore((state) => state.value);

  const { setValue } = eventSearchFilterPanelApi;

  return (
    <div data-cy="event-search-value-field" data-value={value}>
      <TextInput
        label="Value"
        placeholder="Enter value"
        value={value}
        onChange={setValue}
      />
    </div>
  );
};
