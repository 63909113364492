import React, { FC, PropsWithChildren, ComponentProps } from 'react';

type MainProps = ComponentProps<'main'>;

type IdProps = { id: string };

type Props = MainProps & PropsWithChildren & IdProps;

export const Main: FC<Props> = ({ children, className, id, ...rest }) => {
  const clazz = className ? `${className} main-container` : 'main-container';
  const idProps = { id, 'data-cy': id, 'data-testid': id };
  return (
    <main {...rest} {...idProps} className={clazz}>
      {children}
    </main>
  );
};
