import { SelectOption } from '@src/common/types';

export enum ResultsStatus {
  pendingSearch = 'pendingSearch',
  searching = 'searching',
  noResults = 'noResults',
  results = 'results',
  error = 'error',
}

export interface EventSearchResultsStore {
  resultsStatus: ResultsStatus;
}

export interface EventSearchFilterPanelStore {
  monthOptions: SelectOption[];
  monthSelected: string;
  portalOptions: SelectOption[];
  portalsSelected: string[];
  eventOptions: SelectOption[];
  eventsSelected: string[];
  typeOptions: SelectOption[];
  typeSelected: string;
  value: string;
  searchBtnEnabled: boolean;
}
