import React from 'react';

import { MonthSelect } from './filterPanelComponents/MonthSelect';
import { PortalsSelect } from './filterPanelComponents/PortalsSelect';
import { EventsSelect } from './filterPanelComponents/EventsSelect';
import { TypeSelect } from './filterPanelComponents/TypeSelect';
import { ValueField } from './filterPanelComponents/ValueField';
import { SearchButton } from './filterPanelComponents/SearchButton';
import { EventSearchBreadcrumbs } from './EventSearchBreadcrumbs';

export const EventSearchFilterPanel = () => {
  return (
    <div id="filterPanel">
      <EventSearchBreadcrumbs />
      <h5>Filters</h5>
      <MonthSelect />
      <PortalsSelect />
      <EventsSelect />
      <TypeSelect />
      <ValueField />
      <SearchButton />
    </div>
  );
};
