import React from 'react';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { RouteUrl } from '@src/common/util/routeUrl';

export const EventSearchBreadcrumbs = () => {
  return (
    <div id="breadcrumbs">
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Event Search', href: RouteUrl.eventsSearch },
        ]}
      />
    </div>
  );
};
