/* eslint-disable arrow-body-style */
import { SelectInput } from '@abyss/web/ui/SelectInput';
import React from 'react';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const TypeSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.typeOptions);
  const value = useEventSearchFilterPanelStore((state) => state.typeSelected);

  const { setTypeValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-type"
      data-value={value}
      data-options={JSON.stringify(options)}
    >
      <SelectInput
        label="Type"
        placeholder="Please select a type"
        value={value}
        onChange={setTypeValue}
        options={options}
      />
    </div>
  );
};
