/* eslint-disable arrow-body-style */
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import React from 'react';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const EventsSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.eventOptions);
  const value = useEventSearchFilterPanelStore((state) => state.eventsSelected);

  const { setEventValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-events"
      data-value={value}
      data-options={JSON.stringify(options)}
    >
      <SelectInputMulti
        label="Events"
        placeholder="Please select events"
        value={value}
        onChange={setEventValue}
        options={options}
        selectAll
      />
    </div>
  );
};
