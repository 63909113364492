/* eslint-disable arrow-body-style */
import { SelectInput } from '@abyss/web/ui/SelectInput';
import React from 'react';
import {
  eventSearchFilterPanelApi,
  useEventSearchFilterPanelStore,
} from '../../mothership/eventSearchFilterPanel.state';

export const MonthSelect = () => {
  const options = useEventSearchFilterPanelStore((state) => state.monthOptions);
  const value = useEventSearchFilterPanelStore((state) => state.monthSelected);

  const { setMonthValue } = eventSearchFilterPanelApi;

  return (
    <div
      data-cy="event-search-select-month"
      data-value={value}
      data-options={JSON.stringify(options)}
    >
      <SelectInput
        label="Month"
        placeholder="Please select a month"
        value={value}
        onChange={setMonthValue}
        options={options}
      />
    </div>
  );
};
