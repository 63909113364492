/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { create } from 'zustand';
import { EventSearchFilterPanelStore } from '../types';
import { generateMonths } from '../generateMonths';

const monthOptions = generateMonths(new Date());

export const useEventSearchFilterPanelStore =
  create<EventSearchFilterPanelStore>(() => ({
    monthOptions,
    monthSelected: monthOptions[0].value,
    portalOptions: [
      { label: '(Indicative) Optum Pay', value: '1' },
      { label: '(Indicative) Optum Bank', value: '2' },
      { label: '(Indicative) Optum Portal Other', value: '3' },
    ],
    portalsSelected: ['1', '2', '3'],
    eventOptions: [
      { label: '(Indicative) Login', value: '1' },
      { label: '(Indicative) Email change', value: '2' },
      { label: '(Indicative) Password change', value: '3' },
      { label: '(Indicative) Phone number change', value: '4' },
      { label: '(Indicative) Provider enrollment', value: '5' },
    ],
    eventsSelected: ['1', '2', '3', '4', '5'],
    typeOptions: [
      { label: '(Indicative) IP Address', value: '1' },
      { label: '(Indicative) Account ID', value: '2' },
    ],
    typeSelected: '1',
    value: '',
    searchBtnEnabled: false,
  }));

export const eventSearchFilterPanelApi = api();

function api() {
  return {
    setMonthValue,
    setPortalValue,
    setEventValue,
    setTypeValue,
    setValue,
  };

  async function setMonthValue(value: string) {
    useEventSearchFilterPanelStore.setState({ monthSelected: value });
    // fetch dependencies and set state
  }

  function setPortalValue(value: string[]) {
    useEventSearchFilterPanelStore.setState({ portalsSelected: value });
    // get dependencies and set state
  }

  function setEventValue(value: string[]) {
    useEventSearchFilterPanelStore.setState({ eventsSelected: value });
    // get dependencies and set state
  }

  function setTypeValue(value: string) {
    useEventSearchFilterPanelStore.setState({ typeSelected: value });
  }

  function setValue(event: React.ChangeEvent<HTMLInputElement>) {
    useEventSearchFilterPanelStore.setState({ value: event.target.value });
  }
}
