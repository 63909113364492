/* eslint-disable arrow-body-style */
import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { UnauthorizedErrorPage } from '@src/common/Error';
import { LoadingPage } from '@src/routes/provider/ffc/manage/LoadingPage';
import { InvalidTokenErrorPage } from '@src/common/InvalidTokenError';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { Routes } from './routes';
import './assets/css/styles.css';
import { useAuthState } from './app/auth/auth.state';
import { Main } from './app/Main/Main';

const theme = createTheme('uhg');
const router = createRouter(Routes);

export const browser = () => {
  const authStatusKnown = useAuthState((state) => state.authStatusKnown);
  const validToken = useAuthState((state) => state.validToken);
  const error = useAuthState((state) => state.error);
  const { canLogin } = useAuthState((state) => state.permissions);

  if (!authStatusKnown) {
    return (
      <LoadingPage
        isLoading
        message="Please hang on in there while we load up your permissions..."
        title="Loading Your Permissions"
      />
    );
  }

  if (error) {
    return (
      <ThemeProvider>
        <Main className="center-content" id="errorPage">
          Error loading permissions, please refresh the page
        </Main>
      </ThemeProvider>
    );
  }

  if (!validToken) {
    return (
      <ThemeProvider>
        <Main className="center-content" id="invalidTokenPage">
          <InvalidTokenErrorPage />
        </Main>
      </ThemeProvider>
    );
  }

  if (!canLogin) {
    return (
      <ThemeProvider>
        <Main className="center-content" id="no-loginAccessPage">
          <UnauthorizedErrorPage />
        </Main>
      </ThemeProvider>
    );
  }

  return <AbyssProvider theme={theme} router={router} />;
};
